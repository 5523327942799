export const SET_SHOW_MENU = 'SET_SHOW_MENU';
export const SET_SHOW_MAP = 'SET_SHOW_MAP';
export const SET_SHOW_MODAL = 'SET_SHOW_MODAL';
export const SET_SHOW_LOCATION = 'SET_SHOW_LOCATION';
export const SET_SHOW_DIRECTIONS = 'SET_SHOW_DIRECTIONS';

export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_LOCATION_DETAILS = 'SET_LOCATION_DETAILS';
export const SET_VISIBLE_LOCATION = 'SET_VISIBLE_LOCATION';
export const SET_MY_LOCATION = 'SET_MY_LOCATION';