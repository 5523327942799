import React from 'react';
import './assets/scss/home.scss';
import Header from "./header/index";
import Containers from './containers/index';
import Footer from './footer/index';
import { connect } from 'react-redux'
import rootReducer from './store/reducers/index';
require('dotenv').config();

class App extends  React.Component{

  componentDidMount() {}

  render() {
    const data = this.props.state;
    if (!data) return null;
    return (
      <React.Fragment>
        <div id="dengendonor">
          <div id="header-dengendonor">
            <Header data = { data }/>
          </div>
          <div id="container-dengendonor">
            <Containers data = {data} />
          </div>
          <div id="footer-dengendonor">
            <Footer data = {data} />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state
  }
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     // show_menu: () => {
//     //   dispatch(set_show_menu(true));
//     // }
//   }
// };
const mapDispatchToProps = { rootReducer };

export default connect(mapStateToProps, mapDispatchToProps)(App);

