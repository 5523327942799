import combineReducers from "redux/src/combineReducers";
import { locations, visible_location, my_location, location, location_details }  from "./locations";
import { show_modal, show_map, show_menu, show_directions } from "./shows";

const rootReducer = combineReducers({
  show_menu: show_menu,
  show_map: show_map,
  show_modal: show_modal,
  show_directions: show_directions,
  visible_location: visible_location,
  locations: locations,
  location: location,
  location_details: location_details,
  my_location: my_location
});
export default rootReducer;