import React from 'react';
import Container from '@material-ui/core/Container';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { set_show_menu } from "../store/actions/index";
import { connect } from "react-redux";
import Maps from "./maps";
import { menuContentItem } from "../helpers/header";
import FileElectricPowerGuide from '../files/electricpowerguide.pdf';

class Containers extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      item: null
    }
  };


  render() {
    const { show_map, show_menu } = this.props.data;
    const { item } = this.state;

    return (
      <Container maxWidth="lg">
      { show_map?
        <Maps/>
        :
        (
          !show_menu && item ? (this.contentItem()) : (this.contents())
        )
      }
      </Container>
    )
  }

  showMenu() {
    return this.props.show_menu(false);
  }

  contents() {
    return (
      <>
        <div className="menu-content menu-items">
          <Typography onClick = {() => { this.setState({ item: 1 }); this.showMenu()} }>
            電源ドナーとは？<KeyboardArrowRightIcon className="float-right"/>
          </Typography>

          <Typography onClick = {() =>  { this.setState({ item: 2 }); this.showMenu()} }>
            よくある質問 <KeyboardArrowRightIcon className="float-right"/>
          </Typography>

          <Typography onClick ={() =>  { window.open(FileElectricPowerGuide,'_blank') }}>
            電力目安表 <KeyboardArrowRightIcon className="float-right"/>
          </Typography>

          <Typography onClick ={() =>  { this.setState({ item: 4 }); this.showMenu()}}>
            利用規約・免責事項 <KeyboardArrowRightIcon className="float-right"/>
          </Typography>

          <Typography onClick ={() =>  { this.setState({ item: 5 }); this.showMenu()} }>
            電源ドナーリンクポリシー <KeyboardArrowRightIcon className="float-right"/>
          </Typography>

          <div className="line mt-12px"/>
        </div>

      { this.contentFooter()}
      </>
    );
  }

  contentItem() {
    const { item } = this.state;
    const content_item = menuContentItem(item);
    const content_items = (
      <>
        <Typography align="center" className="title">
          { content_item.title }
          <CloseIcon onClick = { () => { this.setState({ item: 0 }); this.props.show_menu(true) }}/>
        </Typography>
        <div className="line-content"/>
        <p className="pt-20px white-space-pre-line menu-content-item-detail" dangerouslySetInnerHTML={{__html:content_item.content}}/>
      </>
    );

    return (
      <div className="menu-content">
        <div className="menu-content-item">
          { content_items }
        </div>
      </div>
    )
  }

  contentFooter() {
    return (
      <div className="menu-footer">
        <div className="menu-footer-1">
          <div className="menu-footer-1-content">
            <span className="font-size-13px">運営会社</span><br/>
            <span className="font-size-18px">株式会社イーコース</span><br/>
            <span className="font-size-14px">info@ecourse.co.jp</span>
          </div>

        </div>
        <div className="menu-footer-2">
          © E-Course Corporation. All rights reserved.
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    state
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    show_menu: (status) => {
      dispatch(set_show_menu(status));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Containers);