import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Logo from '../assets/img/logo.png';
import IconClose from '../assets/img/icon_close.png';
import IconMenu from '../assets/img/menu.svg';
import { connect } from 'react-redux';
import {
  set_show_menu,
  set_show_map,
  set_my_location,
  set_visible_location
} from "../store/actions/index";

class Header extends  React.Component {

  componentDidMount() {
    return this.props.show_map(true);
  }

  showMenu() {
    const { show_menu } = this.props.data;
    show_menu ? this.props.show_map(true) : this.props.show_map(false);

    return this.props.show_menu(!show_menu);
  }

  showMap() {
    const { my_location, locations} = this.props.state;
    if (my_location) {
      this.props.my_location({
        latitude: my_location.latitude,
        longitude: my_location.longitude,
        status: false
      });
    }
    if (locations) {
      this.props.visible_location(locations[0]);
    }
    this.props.show_menu(false);
    this.props.show_map(true);
    return window.location.reload()
  }

  render() {
    const { show_menu } = this.props.data;
    return (
      <AppBar position="static">
        <Toolbar style={{ height: '100%' }} color="inherit">
          <Typography className="img-logo" onClick={() => this.showMap()}>
            <img alt="" src={ Logo } width="140px"/>
          </Typography>
          <Typography color="inherit" className="title" noWrap>
            災害時に役立つ給電場所マップ
          </Typography>
          <IconButton hover="false" className="icon-menu">
            { show_menu ?
              <div>
                <img alt="" src={IconClose} onClick={() => this.showMenu()} className="p-5px h-17px"/>
                <Typography className="img-title">
                  閉じる
                </Typography>
              </div>
              :
              <div>
                <img alt="" src={IconMenu} onClick={() => this.showMenu()} className="p-5px h-17px"/>
                <Typography className="img-title">
                  メニュー
                </Typography>
              </div>
            }
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    show_menu: (status) => {
      dispatch(set_show_menu(status));
    },
    show_map: (status) => {
      dispatch(set_show_map(status));
    },
    my_location: (data) => {
      dispatch(set_my_location(data));
    },
    visible_location: (data) => {
      dispatch(set_visible_location(data));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

