import { SET_SHOW_MENU, SET_SHOW_MAP, SET_SHOW_MODAL, SET_SHOW_DIRECTIONS } from '../actions/type';

export const show_menu = (state = false, {type, payload}) => {
  switch (type) {
    case SET_SHOW_MENU:
      return payload;
    default:
      return state;
  }
};

export const show_map = (state = false, {type, payload}) => {
  switch (type) {
    case SET_SHOW_MAP:
      return payload;
    default:
      return state;
  }
};

export const show_modal = (state = false, {type, payload}) => {
  switch (type) {
    case SET_SHOW_MODAL:
      return payload;
    default:
      return state;
  }
};

export const show_directions = (state = false, {type, payload}) => {
  switch (type) {
    case SET_SHOW_DIRECTIONS:
      return payload;
    default:
      return state;
  }
};