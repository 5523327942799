import React from 'react';
import { get_locations, get_location_details } from "../helpers/api_helper";
import {
  set_locations,
  set_location,
  set_location_details,
  set_visible_location,
  set_show_modal,
  set_show_directions,
  set_my_location
} from "../store/actions/index";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";

import IconInfo from '../assets/img/icon_info.svg';
import IconPowerOutageTrue from '../assets/img/icon_power_outage_true.svg';
import IconPowerOutageFalse from '../assets/img/icon_power_outage_false.svg';
import IconLocationPowerOutageFalse from '../assets/img/icon_location_power_outage_false.svg';
import IconLocationPowerOutageTrue from '../assets/img/icon_location_power_outage_true.svg';
import IconDirection from '../assets/img/icon_direction.svg';
import IconYouTube from '../assets/img/icon_youtube.svg';
import { connect } from "react-redux";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import CloseIcon from '@material-ui/icons/Close';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Swal from 'sweetalert2'
import { isMobile, matchUrls } from '../helpers/common';

let locations = [];
let location_details = {};
let location_detail_status = null;

class Maps extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }

  async componentDidMount() {
    locations = await get_locations;
    if (locations && locations.length > 0) {
      await this.props.set_location(locations[0]);
      await this.props.set_locations(locations);
    }
  }

  render() {
    if (!locations || locations.length === 0) return null;
    const { show_modal } = this.props;
    const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&callback=&libraries=geometry,places,drawing&v=weekly&language=ja&region=JP`;

    return (
      <>
        <Map
          isMarkerShown
          googleMapURL={googleMapURL}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          props={this.props}
        />
        { show_modal ? <LocationDetail props={this.props} /> : null}
      </>
    );
  }
}

const Map = withScriptjs(withGoogleMap((props) => {
  if (!locations || locations.length === 0) return null;

  props = props.props;
  const { my_location, visible_location, location } = props;

  return (
    <>
      <div
        className="my-location"
        onClick={() => {
          props.set_visible_location({});
          props.set_show_directions(false);
          myLocation(props, true)
        }}
      >
        <div className="text" />
      </div>

      { my_location.status ? (

        <GoogleMap
          isMarkerShown
          clickableIcons={false}
          defaultZoom={10}
          center={new window.google.maps.LatLng(my_location.latitude, my_location.longitude)}
          defaultCenter={new window.google.maps.LatLng(my_location.latitude, my_location.longitude)}
          onBoundsChanged={props.onBoundsChanged}
        >
          <Marker
            key={-1}
            position={new window.google.maps.LatLng(my_location.latitude, my_location.longitude)}
          />

          {
            locations.map((location) => {
              const { id, latitude, longitude, power_outage } = location;
              const visible = visible_location && visible_location.id === id;

              return (
                <Marker
                  key={location.id}
                  position={{ lat: latitude, lng: longitude }}
                  icon={power_outage ? IconLocationPowerOutageTrue : IconLocationPowerOutageFalse}
                  onClick={async () => {
                    await setMarkerLocation(true, id, visible, location, props);
                  }}
                >
                  { visible ?
                    <>
                      {Location(props)}
                      {setTimeoutAddClassName()}
                    </>
                    : ''
                  }
                </Marker>
              )
            })
          }
        </GoogleMap>
      ) : (
        <GoogleMap
          clickableIcons={false}
          defaultZoom={10}
          defaultCenter={new window.google.maps.LatLng(location.latitude, location.longitude)}
          center={new window.google.maps.LatLng(location.latitude, location.longitude)}
          onBoundsChanged={props.onBoundsChanged}
        >
          {
            locations.map((location) => {
              const { id, latitude, longitude, power_outage } = location;
              const visible = visible_location && visible_location === id;

              return (
                <Marker
                  key={location.id}
                  position={{ lat: latitude, lng: longitude }}
                  icon={power_outage ? IconLocationPowerOutageTrue : IconLocationPowerOutageFalse}
                  onClick={async () => {
                    await setMarkerLocation(false, id, visible, location, props);
                  }}
                >
                  { visible ?
                    <>
                      {Location(props)}
                      {setTimeoutAddClassName()}
                    </>
                    : ''
                  }
                </Marker>
              )
            })
          }
        </GoogleMap>
      )}
    </>)
}));

//Set market for location
const setMarkerLocation = async (status, index, visible, location, props) => {
  props.set_visible_location(!visible ? index : null);
  props.set_location(location);
  props.set_show_directions(false);

  if (status) {
    props.set_my_location({
      latitude: props.my_location.latitude,
      longitude: props.my_location.longitude,
      status: false
    });
  }

  //Get location details
  location_details = await get_location_details(location.id);
  props.set_location_details(location_details);
  return props;
};

//Add className to id line-clamp-2
const setTimeoutAddClassName = () => {
  setTimeout(() => {
    const line = document.getElementById('line-clamp-2');
    if (line) {
      line.classList.add('line-clamp-2')
    }
  }, 300);
};

//Popup show the location.
const Location = (props) => {
  const { location, location_details } = props;
  if (!location || !location_details) return;
  const { power_outage } = location;

  const set_infoLocation = setInfoLocation(location_details);
  location_detail_status = set_infoLocation.status;

  return (
    <InfoWindow>
      <ClickAwayListener onClickAway={() => {
        props.set_visible_location({});
        props.set_show_directions(false)
      }}>
        <div className="info-window" onClick={() => {
          props.set_show_modal(true);
          props.set_visible_location({});
          props.set_my_location({
            latitude: props.my_location.latitude,
            longitude: props.my_location.longitude,
            status: false
          })
        }}>
          <div className="header">
            {!set_infoLocation.header_title1 ? '' :
              (
                <div className="title">
                  <span>{set_infoLocation.header_title1}</span>
                  <span>{set_infoLocation.header_title2}</span>
                </div>
              )}

            <div className="icon">
              <img alt="" src={IconInfo} />
              <span>詳細</span>
            </div>
          </div>
          <hr />
          <div className="body pb-4px">
            <div className="title">
              <img alt="" className="icon" src={power_outage ? IconPowerOutageTrue : IconPowerOutageFalse} />
              <div className="name">{set_infoLocation.body_title}</div>
            </div>
            <div id="line-clamp-2" className="content max-h-36px text-left">{set_infoLocation.body_content}</div>
          </div>
        </div>
      </ClickAwayListener>
    </InfoWindow>
  );
};

//Popup show the location detail.
const LocationDetail = (props) => {
  props = props.props;

  const { location_details, show_modal, location } = props;
  if (!location_details || !location) return;

  let { time_from, time_to, loc_desc, other_info, client_name, facility_name, calculated_remaining_power, remarks, youtube_url } = location_details;
  let { power_outage } = location;

  time_from = time_from ? time_from : 0;
  time_to = time_to ? time_to : 0;
  const hours = (time_to - time_from) || 0;

  loc_desc = replaceUrls(loc_desc).replace(/\n/g, '<br/>');
  other_info = replaceUrls(other_info).replace(/\n/g, '<br/>');

  return (
    <Dialog
      open={show_modal}
      className='dialog-show-detail-location'
    >
      <div className="info-window">
        <div className="header">
          <div className="title">
            <span>{client_name || ''}</span>
            <span>{facility_name || ''}</span>
          </div>
          <div className="icon">
            <CloseIcon onClick={() => props.set_show_modal(!show_modal)} />
          </div>
        </div>
        <hr />
        <div className="body">

          <div className="title">
            <img alt="" className="icon" src={power_outage ? IconPowerOutageTrue : IconPowerOutageFalse} />
            <div className="name">
              {
                location_detail_status === 0 ?
                  ('停電時は給電場所になります')
                  :
                  (`電源残量：${calculated_remaining_power || '-'} kWh`)
              }
            </div>
          </div>

          <div className="content text-left">
            <div className="white-space-pre-line" dangerouslySetInnerHTML={{ __html: remarks.replace(/\n/g, '<br/>') }} />
          </div>

          <div className="content">
            <div className="btn direction" onClick={() => {
              props.set_show_directions(true);
              props.set_show_modal(false);
              checkDirections(props.my_location, props.set_my_location, props.location);
            }}>
              <img alt="" className="icon" src={IconDirection} />
              <div className="text pl-9px">
                この場所をルート検索 <br />（Google マップ）
              </div>
            </div>
          </div>

          {
            youtube_url ? (
              <div className="content">
                <div
                  className="btn youtube"
                  onClick={() => window.open(youtube_url || '', "_blank")}
                >
                  <img alt="" className="icon" src={IconYouTube} />
                  <div className="text pl-9px">（紹介動画）</div>
                </div>
              </div>

            ) : ('')
          }

          <div className="content text-left">
            [提供時間]
            <br />
            {time_from === time_to ? `${time_from}時` : `${time_from}時〜${time_to}時`}
            {hours !== 24 ? '' : (`（${hours}時間）`)}
          </div>

          <div className="content text-left">
            [場所の説明]
            <br />
            <div className="white-space-pre-line" dangerouslySetInnerHTML={{ __html: loc_desc }} />
          </div>

          <div className="content text-left">
            [その他の情報]
            <br />
            <div className="white-space-pre-line" dangerouslySetInnerHTML={{ __html: other_info }} />
          </div>
        </div>
      </div>
    </Dialog>
  )
};

const setInfoLocation = (location_detail) => {
  let status = 0;
  //status 0:
  //status 1:
  //status 2:

  const { power_outage, calculated_remaining_power, client_name, facility_name, remarks } = location_detail;

  if (!power_outage) {
    status = 0;
  } else if (power_outage && calculated_remaining_power > 0) {
    status = 1;
  } else if (power_outage && calculated_remaining_power <= 0) {
    status = 2;
  }

  let header_title1 = client_name;
  let header_title2 = facility_name;
  let body_title = '';
  let body_content = remarks;
  switch (status) {
    case 0: {
      body_title = '停電時は給電場所になります';
      break;
    }
    case 1: {
      body_title = '電源残量：' + calculated_remaining_power + ' kWh';
      break;
    }
    case 2: {
      body_title = '電源残量：ー kWh';
      break;
    }
    default: {
      body_title = '';
      break;
    }
  }
  return ({ header_title1, header_title2, body_title, body_content, status });
};

//Create directions url to google maps.
const directionUrl = (my_location, location) => {
  return `https://www.google.com/maps/dir/?api=1&origin=
    ${my_location.latitude},${my_location.longitude}
    &destination=${location.latitude},${location.longitude}&language=ja`;
};

//Check my location before directions to google map.
const checkDirections = (my_location, set_my_location, location) => {
  if (my_location && my_location.latitude && my_location.longitude) {
    const direction_url = directionUrl(my_location, location);
    return window.open(direction_url || '', "_blank");

  } else {
    navigator.geolocation.getCurrentPosition((position) => {
      set_my_location({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        detail: {},
        status: false
      });
      const my_location = { latitude: position.coords.latitude, longitude: position.coords.longitude };
      const direction_url = directionUrl(my_location, location);
      return window.open(direction_url || '', "_blank");

    }, () => {
      return popupPositioningOnTheMap();
    });
  }
};

//Check my location have locate?
const myLocation = (props, status) => {
  navigator.geolocation.getCurrentPosition((position) => {
    props.set_my_location({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      detail: {},
      status: status
    })
  }, () => {
    popupPositioningOnTheMap();
  });
};

//Pop-up notification when there is no location.
const popupPositioningOnTheMap = () => {
  let html = '位置情報が取得できませんでした。利用するには設定メニューでブラウザの位置情報利用をオンにしてください。';
  if (isMobile()) {
    html = '位置情報が取得できませんでした。<br>利用するには設定メニューでブラウザの位置情報利用をオンにしてください。';
  }

  return (
    Swal.fire({
      html: html,
      showCloseButton: true,
      showCancelButton: false,
      showDenyButton: false,
      showConfirmButton: false,
      customClass: {
        container: 'map-swal2-container'
      }
    })
  )
};

//replace urls in string to tag a, to link to url.
const replaceUrls = (string) => {
  const match_urls = matchUrls(string);
  if (match_urls.length > 0) {
    match_urls.map((match_url) => {
      return string = string.replace(match_url, `<a href=${match_url} target="_blank" class="text-link line-clamp-1">${match_url}</a>`);
    })
  }
  return string;
};

const mapStateToProps = (state) => {
  return state
};

const mapDispatchToProps = (dispatch) => {
  return {
    set_show_modal: (data) => {
      dispatch(set_show_modal(data));
    },
    set_show_directions: (data) => {
      dispatch(set_show_directions(data));
    },
    set_locations: (data) => {
      dispatch(set_locations(data));
    },
    set_location: (data) => {
      dispatch(set_location(data));
    },
    set_location_details: (data) => {
      dispatch(set_location_details(data));
    },
    set_visible_location: (data) => {
      dispatch(set_visible_location(data));
    },
    set_my_location: (data) => {
      dispatch(set_my_location(data));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Maps);
