export const menuContentItem = (item) => {
  let title = '';
  let content = '';

  switch (item) {
    case 1:
      title = '電源ドナーとは？';
      content = '電源ドナーは、地震や大規模停電などの災害時のための地域に貢献する電力情報提供サービスです。\n' +
        '\n今、電気がどこにこのくらいある！（給電場所）を地図でわかりやすくお伝えいたします。';
      break;
    case 2:
      title = 'よくある質問';
      content = 'Q. 電源ドナーとは\n' +
        'A. 電源ドナーは、災害時に電力を提供することができる給電拠点をお知らせする情報提供サービスです。\n' +
        '\n' +
        'Q. 給電拠点を知る方法は？\n' +
        'A. 「電源ドナー」で検索し、スマホまたはPCでWEBページにアクセスしてください。\n' +
        '\n' +
        'Q. どこで提供される？\n' +
        'A. 地図上に表示される電源ドナーの場所（施設）で、災害時の停電時に提供されます。\n' +
        '\n' +
        'Q. 費用は？\n' +
        'A. 個人の場合、すべて無料です。\n' +
        '\n' +
        'Q. 電気量が表示されません。\n' +
        'A. 災害時に停電が発生した場合、提供者からの依頼により提供電力量が表示されます。';
      break;
    case 4:
      title = '利用規約・免責事項';
      content = '株式会社イーコース（以下「当社」という）が管理及び運営するウェブサイト（以下「当ウェブサイト」という）をご利用される前に、次の利用規約及び免責事項（以下「当規約」という）をご確認ください。\n' +
        '当ウェブサイトをご利用された場合、お客様は当規約にご同意されたものとさせていただきます。\n' +
        '\n' +
        '<div class="text-center">利用規約</div>' +
        '\n' +
        '1．著作権等について\n' +
        '当ウェブサイト及び当ウェブサイトに掲載された全ての内容に関する権利は、当社又は原著作者その他権利者が有します。著作権法その他法律で認められた利用範囲を超えての利用は一切できません。\n' +
        '\n' +
        '2．商標等について\n' +
        '「株式会社イーコース」その他当ウェブサイトに掲載された会社名、商品・サービス名その他当ウェブサイトにおいて個別に示されている商標の商標権その他の権利は権利者に属します。\n' +
        '\n' +
        '3．リンクについて\n' +
        'リンクポリシーをご覧ください。\n' +
        '\n' +
        '4．禁止事項について\n' +
        '当ウェブサイトのご利用に際し、公序良俗に反する行為、法令に反する行為、その他当社が不適切と判断する行為又はこれらに該当する恐れのある行為は禁止いたします。\n' +
        '\n' +
        '5．準拠法及び管轄裁判所\n' +
        '当ウェブサイト及び当規約の成立、効力、解釈及び履行については日本国法に準拠します。また、当ウェブサイト及び当規約に関して訴訟の必要が発生した場合は、東京地方裁判所を管轄裁判所とさせていただきます。\n' +
        '\n' +
        '6．当規約の改定について\n' +
        '当規約の内容につきましては、予告なしに改定される場合がございます。\n' +
        '\n' +
        '\n' +
        '<div class="text-center">免責事項</div>' +
        '\n' +
        '1．当ウェブサイト上のコンテンツについて、できる限り正確に保つように努めていますが、掲載内容の正確性・完全性・信頼性・最新性を保証するものではございません。\n' +
        '\n' +
        '2．当ウェブサイトはシステム障害又はサーバのメンテナンス等により、予告なく一時的又は長期に中止される場合がございます。\n' +
        '\n' +
        '3．当ウェブサイト及び当ウェブサイトに掲載されている情報を利用することで発生した紛争や損害に対し、当社は責任を負わないものとします。\n' +
        '\n' +
        '4．理由の如何に関わらず、当ウェブサイトに掲載されている情報の変更及び当ウェブサイトの運用の中断又は中止によって生じるいかなる損害についても当社は責任を負わないものとします。\n' +
        '\n' +
        '\n' +
        '<div class="text-center">推奨環境</div>' +
        '\n' +
        '推奨ブラウザについて\n' +
        'Google Chrome、Microsoft  Edge\n' +
        '\n' +
        '上記以外のブラウザをご利用の方\n' +
        'その他のブラウザをご利用の方は、基本的に表示はされますが、表示の保証はいたしかねます。\n' +
        '\n' +
        '印刷について\n' +
        'ブラウザ/OSの特性上、印刷結果が実際の画面表示と異なる場合があります。あらかじめご了承ください。\n' +
        '\n' +
        'ブラウザ特有の仕様について\n' +
        'ページの表示や操作を行う際に、ブラウザ特有の仕様によって他のブラウザとは動作が異なる場合があります。あらかじめご了承ください。\n' +
        '\n' +
        '\n' +
        '<div class="text-center">セキュリティについて</div>' +
        '\n' +
        '当ウェブサイトとブラウザとの間でSSLによる暗号化通信を行い情報の盗聴や改ざんを防止しています。なお、通常は使用する設定になっていますが、ブラウザによってはSSLの機能を使用しない設定のものもあります。お使いのブラウザのヘルプなどをご覧いただき、あらかじめSSLによる暗号化通信を使用可能にしてください。\n' +
        '\n' +
        'クッキー（Cookie）について\n' +
        '当ウェブサイトは、クッキー（Cookie）の技術を使用して、お客さま情報の保持及びお客さまの利用登録に関する情報を収集します。\n' +
        'クッキーは、当Webサイト群のご利用ごとに破棄し、その情報は、お客さまのハードディスクに保存されることはありません。\n' +
        'また、お客さまがクッキーを拒否する設定を行った場合、当Webサイト群のサービスのご利用が制限される場合がありますので、あらかじめご了承ください。';
      break;
    case 5:
      title = '電源ドナーリンクポリシー';
      content = '他サイトへのリンクについて\n' +
        '株式会社イーコース（以下「当社」という）は、当社が管理及び運営するウェブサイト（以下「当ウェブサイト」という）において、\n' +
        '提携会社及びその他のサイトへリンクする場合がございますが、当該サイトをご利用になったことにより生じたいかなる損害についても責任を負わないものとします。\n' +
        '\n' +
        '当Webサイト群へのリンクについて\n' +
        '事前に確認が不要です。<span class="text-link">info@ecourse.co.jp</span> まで、リンク元のURL、ご担当者名及びメールアドレスをご連絡ください。\n' +
        'なお、リンクによって発生したトラブルや損害賠償問題等につき、当社は何ら責任を負いません。\n' +
        'リンクする場合は、トップページのURL <a class="text-link" href="https://dengen-donor.com">https://dengen-donor.com</a> でお願いいたします。当社が不適切と判断する場合、リンクの削除を要請する場合があります。\n' +
        '\n' +
        'また、次に掲げるサイトからのリンクはお断りいたします。\n' +
        '・当社又はその役職員を誹謗・中傷するサイト\n' +
        '・当社の商品・サービスを誹謗・中傷するサイト\n' +
        '・違法の可能性のある情報を提供するサイト\n' +
        '・公序良俗に反する可能性のある情報を提供するサイト\n' +
        '・独自のフレーム内に当ウェブサイトのページを取り込んだ形など、当社のウェブサイトであることが不明確となるサイト';
      break;
    default:
      title = '';
      content = '';
  }

  return { title, content };
};