import { createAction } from 'redux-actions';
import {
  SET_SHOW_MENU,
  SET_SHOW_MAP,
  SET_SHOW_DIRECTIONS,
  SET_SHOW_LOCATION,
  SET_SHOW_MODAL,
  SET_LOCATIONS,
  SET_LOCATION,
  SET_LOCATION_DETAILS,
  SET_VISIBLE_LOCATION,
  SET_MY_LOCATION
} from './type';

export const set_show_menu = createAction(SET_SHOW_MENU);
export const set_show_map = createAction(SET_SHOW_MAP);
export const set_show_modal = createAction(SET_SHOW_MODAL);
export const set_show_location = createAction(SET_SHOW_LOCATION);
export const set_show_directions = createAction(SET_SHOW_DIRECTIONS);

export const set_locations = createAction(SET_LOCATIONS);
export const set_location = createAction(SET_LOCATION);
export const set_location_details = createAction(SET_LOCATION_DETAILS);
export const set_visible_location = createAction(SET_VISIBLE_LOCATION);
export const set_my_location = createAction(SET_MY_LOCATION);