import React, {useState, useEffect} from 'react';
import kyowa from '../assets/img/kyowa.png';
import shiroishi from '../assets/img/shiroishi.png';
import daisyo from '../assets/img/daisyo.png';

export default function Footer() {
	// 広告企業配列
	const ads = [
		[ '協和運輸倉庫', kyowa, 'http://www.kyowa-unyu-soko.co.jp/' ],
		[ '白石倉庫', shiroishi, 'https://www.shiroishi.co.jp' ],
		[ '大昇物流', daisyo, 'http://daisyo-s.co.jp' ],
	];

	const [count, setCount] = useState(0);

	useEffect(() => {
		const time = setInterval(() => {
			setCount(prevCount => (prevCount + 1) % ads.length);
		}, 8000);
		return () => clearInterval(time);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="footer-content">
			<img key={ads[count][2]}
				alt={ads[count][0]}
				src={ads[count][1]}
				onClick={() => window.open(ads[count][2], "_blank")}
			/>
		</div>
	);
}
