import { SET_LOCATIONS,SET_LOCATION,  SET_VISIBLE_LOCATION, SET_MY_LOCATION, SET_LOCATION_DETAILS } from '../actions/type';

export const locations = (state=[], action) => {
  switch (action.type) {
    case SET_LOCATIONS:
      return action.payload.map(x => Object.assign({}, x));
    default:
      return state
  }
};

export const location = (state={}, action) => {
  switch (action.type) {
    case SET_LOCATION:
      return Object.assign({}, action.payload);
    default:
      return state
  }
};

export const location_details = (state={}, action) => {
  switch (action.type) {
    case SET_LOCATION_DETAILS:
      return Object.assign({}, action.payload);
    default:
      return state
  }
};

export const visible_location = (state = null, action) => {
  switch (action.type) {
    case SET_VISIBLE_LOCATION:
      return action.payload;
    default:
      return state;
  }
};

export const my_location = (state = {latitude: null, longitude: null, detail: {}, status: false }, action) => {
  switch (action.type) {
    case SET_MY_LOCATION:
      return Object.assign({}, action.payload);
    default:
      return state;
  }
};