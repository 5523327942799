export const get_locations = fetch(`${process.env.REACT_APP_URL_API}/d_facility`)
  .then(async res => await res.json())
  .then(
    async (locations) => {
      locations = await locations.filter( r => r.latitude !==  0 && r.longitude !==  0);
      return locations;
    }, (error) => {
      errors(error);
    }
  );

export const get_location_details = (id) => fetch(`${process.env.REACT_APP_URL_API}/d_facility/${id}`)
  .then(async res => await res.json())
  .then(
    async (location_details) => {
      return await location_details;
    }, (error) => {
      errors(error);
    }
  );

function errors (error) {
  console.log('error', error)
}