//Check width screen
export const isMobile = () => {
  return window.screen.width <= 500;
};

//match url from string
export const matchUrls = (string) => {
  if (!string) return [];
  // eslint-disable-next-line
  const regex = /(?:(?:https?):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;
  const matches = string.match(regex);
  return matches || [];
};